import { VKShareButton, OKShareButton } from 'react-share';
import { FaVk, FaOdnoklassniki } from 'react-icons/fa';

const Share = () => {
	const shareUrl = 'https://ny2024.xn--80apaohbc3aw9e.xn--p1ai/'
	const title = 'Я нарядил мою елочку полезных финансовых привычек! Вы можете нарядить свою на моифинансы.рф'

	return (
		<div className=' align-items-center'>
			<h6>
				Поделиться результатом в социальных сетях:
			</h6>
			<div>
				<VKShareButton url={shareUrl} title={title}>
					<FaVk size={32} round color='#0177FF' />
				</VKShareButton>

				<OKShareButton url={shareUrl} title={title}>
					<FaOdnoklassniki size={32} round color='#F6931D' />
				</OKShareButton>
			</div>
		</div>
	)
}

export default Share
