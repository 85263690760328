import classNames from 'classnames/bind';
import styles from './Pdf.module.scss';
import { useAllHabits } from './../../hooks/useAllHabits';
import santa from '../../assets/img/santa-claus.jpg';
import logo from '../../assets/img/logo.jpg';
import blueFrame from '../../assets/img/blue-frame.jpg';

const cn = classNames.bind(styles);

const Pdf = () => {

	const { allHabits } = useAllHabits();

	return (
		<div id='pdf-content' className={cn('wrapper')}>
			<div className={cn('santa')}>
				<img className={cn('santa-img')} src={santa} alt="" />
				<img className={cn('logo-img')} src={logo} alt="" />
			</div>
			<div className={cn('frame')}>
				<div className={cn('frame-title')}>Мои финЗОЖ цели на 2024 год</div>
				<div className={cn('frame-text')}>Обещаю себе выработать эти полезные привычки.</div>
				<img className={cn('frame-img')} src={blueFrame} alt="" />
				<ul className={cn('list')}>
					{
						allHabits?.map((el) =>
							<li
								key={el.id}
								className={cn('list-item')}
							>
								{el.changedText ? el.changedText : el.text}
							</li>
						)
					}
				</ul>
			</div>
		</div>
	)
}

export default Pdf;
