export const badHabits = [
	{
		id: '1',
		text: 'Хранить все накопления дома',
		changedText: 'Хранить деньги в банке и надежных ценных бумагах',
	},
	{
		id: '2',
		text: 'Инвестировать на кредитные деньги',
		changedText: 'Инвестировать только то, что не жалко потерять',
	},
	{
		id: '3',
		text: 'Обновлять телефон и справлять праздники в кредит',
		changedText: 'Копить на крупные покупки',
	},
	{
		id: '4',
		text: 'Покупать дорогие вещи, чтобы произвести впечатление',
		changedText: 'Избавиться от «брендозависимости»',
	},
	{
		id: '5',
		text: 'Принимать финансовые решения на эмоциях',
		changedText: 'Перед важными решениями брать паузу',
	},
	{
		id: '6',
		text: 'Покупать подарки накануне праздников',
		changedText: 'Покупать подарки заранее',
	},
	{
		id: '7',
		text: 'Брать такси там, где можно пройти пешком',
		changedText: 'Разумно экономить на транспорте',
	},
	{
		id: '8',
		text: 'Постоянно тратиться на кофе «на вынос», такси, алкоголь, сигареты',
		changedText: 'Экономить на мелких регулярных расходах',
	},
	{
		id: '9',
		text: 'Откладывать оплату налогов, штрафов и услуг ЖКХ',
		changedText: 'Вовремя оплачивать штрафы, налоги, ЖКУ, не допуская пени',
	},
	{
		id: '10',
		text: 'Забывать отключать «бесплатные» подписки',
		changedText: 'Вовремя отключать «бесплатные» подписки',
	},
	{
		id: '11',
		text: 'Брать кредиты, чтобы закрыть другие кредиты',
		changedText: 'Брать кредит только на развитие',
	},
	{
		id: '12',
		text: 'Подписывать договоры не глядя',
		changedText: 'Внимательно читать все договоры',
	},
	{
		id: '13',
		text: 'Выбирать в качестве пароля дату своего дня рождения',
		changedText: 'Использовать сложные пароли',
	},
	{
		id: '14',
		text: 'Не проверять надежность продавца в интернете',
		changedText: 'Проверять безопасность интернет-ресурсов',
	},
	{
		id: '15',
		text: 'Заходить в интернет-банк через открытые точки Wi-Fi',
		changedText: 'Пользоваться защищенными каналами связи',
	},
	{
		id: '16',
		text: 'Давать деньги в долг без расписки',
		changedText: 'Давать деньги в долг только с распиской',
	},
	{
		id: '17',
		text: 'Распоряжаться карманными деньгами ребенка',
		changedText: 'Давать ребенку финансовую свободу',
	},
	{
		id: '18',
		text: 'Принимать финансовые решения единолично',
		changedText: 'Советоваться о крупных покупках с семьей',
	},
	{
		id: '19',
		text: 'Получать зарплату  «в конверте»',
		changedText: 'Работать «в белую»',
	},
	{
		id: '20',
		text: 'Работать без трудового договора',
		changedText: 'Заключать трудовой договор',
	},
];

export const goodHabits = [
	{ id: '21', text: 'Вести учет доходов и расходов' },
	{ id: '22', text: 'Откладывать деньги на непредвиденные расходы' },
	{ id: '23', text: 'Инвестировать свободные средства' },
	{ id: '24', text: 'Перед крупными покупками брать паузу' },
	{ id: '25', text: 'Сравнивать цены на товары и услуги' },
	{ id: '26', text: 'Советоваться с близкими по поводу крупных покупок'},
	{ id: '27', text: 'Ходить в магазин со списком' },
	{ id: '28', text: 'Пробовать чинить вещи, прежде чем покупать новые' },
	{ id: '29', text: 'Получать налоговый вычет' },
	{ id: '30', text: 'В полной мере использовать все льготы' },
	{ id: '31', text: 'Общаться с неизвестными онлайн-магазинами только через маркетплейс'},
	{ id: '32', text: 'Регулярно обновлять пароли и антивирус' },
	{ id: '33', text: 'Не разговаривать по телефону с представителями «служб безопасности банков» и «госорганов»'},
	{ id: '34', text: 'Не переходить в сторонние мессенджеры при общении с покупателями и продавцами на Авито или Юле'},
	{ id: '35', text: 'Предупреждать старших родственников о приемах мошенников'},
	{ id: '36', text: 'Обсуждать с детьми финансовые планы семьи' },
	{ id: '37', text: 'Ставить в машине подкасты по финансовой грамотности'},
	{ id: '38', text: 'Голосовать в опросах по благоустройству'},
	{ id: '39', text: 'Участвовать в конкурсах на получение грантов'},
	{ id: '40', text: 'Участвовать в благотворительности через надежные каналы'},
];
