import classNames from 'classnames/bind';
import styles from './EmailModal.module.scss';
import Input from '../Input/Input';
import { modalWindowText } from '../../../data/siteText';
import Share from '../../Share/Share';

const cn = classNames.bind(styles);

const EmailModal = ({ setModalShow }) => {

	return (
		<div className={cn('modal-window', 'container')}>
			<div className={cn('modal-container')}>
				<p className={cn('modal-window-text', 'mb-3 mb-xl-5')}>
					{modalWindowText.chosenHabitsText}
				</p>
				<Input setModalShow={setModalShow} />
				<Share />
			</div>
			
		</div>
	)
}

export default EmailModal
